import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en.json';
import translationIT from './it.json';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
const resources = {
  it: {
    translation: translationIT,
  },
  en: {
    translation: translationEN,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    supportedLngs: ['it', 'en'],
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'language',
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export const translate = (text) => {
  return i18n.t(text);
};

export const toogleLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

export const lang = () => {
  return i18n.language;
};

export default i18n;
