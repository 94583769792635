import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Box, Paper, IconButton, Typography, Container } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { authGet, authPost } from '../../services/auth';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Chip } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const NUM_OF_MSGS_LOADED_EACH_CALL = 30;

export const BlockTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 32.45px;
  letter-spacing: -0.02em;
  margin-top: 0;
  margin-bottom: -10px;
  margin-left: 25px;
`;

const ClientChat = () => {
  const { tripRequestCode, busPartnerCode } = useParams();
  const { t } = useTranslation();

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMoreMessages, setLoadingMoreMessages] = useState(true);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [cursor, setCursor] = useState(null);
  const firstMessageRef = useRef(null);
  const editorRef = useRef(null);

  // Get messages
  const getMessages = async ({ tripRequestCode, cursor }) => {
    const queryParams = new URLSearchParams();
    queryParams.append('limit', NUM_OF_MSGS_LOADED_EACH_CALL);
    if (cursor !== null) {
      queryParams.append('cursor', cursor);
    }

    authGet({
      route: `v1/conversations/trip-requests/${busPartnerCode}/${tripRequestCode}/customer?${queryParams.toString()}`,
    }).then((result) => {
      setLoadingMoreMessages(false);
      console.log(result);
      if (result.data != null) {
        if (result.data.data != null) {
          setMessages([
            ...messages,
            ...result.data.data.map((message) => ({
              id: message.id,
              type: message.type,
              text: message.message,
              timestamp: message.creationDate,
              isSent: message.direction === 1,
            })),
          ]);
        }

        setHasPrevious(result.data.hasPrevious);
        setCursor(result.data.cursor);
      }
    });
  };

  const observer = new IntersectionObserver((e) => {
    console.log('IntersectionObserver', e);
    if (e[0].isIntersecting && hasPrevious && !isLoadingMoreMessages) {
      console.log(
        'Load more messages tripRequestCode',
        tripRequestCode,
        cursor,
      );
      setLoadingMoreMessages(true);
      getMessages({ tripRequestCode, cursor }).then(() =>
        observer.unobserve(firstMessageRef.current),
      );
    }
  });

  useEffect(() => {
    getMessages({ tripRequestCode, cursor: null });
  }, [tripRequestCode]);

  useLayoutEffect(() => {
    console.log(firstMessageRef.current);
    if (firstMessageRef.current) {
      observer.observe(firstMessageRef.current);
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
    setIsLoading(true);

    const currentTime = new Date();

    await authPost({
      route: `v1/conversations/trip-requests/${tripRequestCode}/customer-send`,
      body: {
        busPartnerCode: busPartnerCode,
        message: newMessage,
        clientTimestamp: new Date().toISOString(),
      },
    }).then(() => {
      setIsLoading(false);
      setNewMessage('');
      editorRef.current?.setContent('');
      setMessages((prev) => [
        {
          id: (prev[prev.length]?.id || 0) + 1,
          text: newMessage,
          timestamp: currentTime,
          isSent: true,
        },
        ...prev,
      ]);
    });
  };

  return (
    <>
      <BlockTitle>{t('TripDetail.Conversation')}</BlockTitle>
      <Container sx={{ py: 2 }}>
        <Box
          sx={{
            borderRadius: '5px',
            border: '1px solid #cbd5e1',
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}>
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              p: 2,
              display: 'flex',
              flexDirection: 'column-reverse',
              gap: 1,
              bgcolor: '#eef2ff',
            }}>
            {isLoadingMoreMessages
              ? [
                  ...messages.map((message, index) => (
                    <ChatEntry
                      key={message.id}
                      message={message}
                      index={index}
                      messagesLength={messages.length}
                      firstMessageRef={firstMessageRef}
                    />
                  )),
                  <Box
                    key={'loading'}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center', // Center the spinner horizontally
                      justifyContent: 'center', // Center the spinner vertically
                      height: '24px', // Ensure the Box takes the full height of its container
                      width: '100%', // Ensure the Box takes the full width of its container
                    }}>
                    <CircularProgress color={'primary'} size={24} />
                  </Box>,
                ]
              : messages.map((message, index) => (
                  <ChatEntry
                    key={message.id}
                    message={message}
                    index={index}
                    messagesLength={messages.length}
                    firstMessageRef={firstMessageRef}
                  />
                ))}
          </Box>
          <Box
            sx={{
              bgcolor: 'white',
              marginBottom: '-35px',
              borderTop: '1px solid #cbd5e1',
            }}>
            <Box
              sx={{
                display: 'flex',
                minHeight: '150px',
                flexDirection: 'row',
              }}>
              <Box
                sx={{
                  flexGrow: 1,
                }}>
                <Editor
                  onInit={(_evt, editor) => (editorRef.current = editor)}
                  tinymceScriptSrc="/tinymce/tinymce.min.js"
                  licenseKey={'gpl'}
                  onEditorChange={(content) => {
                    setNewMessage(content);
                  }}
                  init={{
                    height: 140,
                    language_url: '/tinymce/lang/it.js',
                    language: 'it',
                    menubar: false,
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'charmap',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'code',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'preview',
                      'wordcount',
                    ],
                    toolbar: 'none ',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    setup: (editor) => {
                      editor.on('init', () => {
                        // Remove border styles directly from the editor's container
                        editor.getContainer().style.border = 'none';
                      });
                    },
                  }}
                />
              </Box>
              <SendButton
                isLoading={isLoading}
                messageToSend={newMessage}
                handleSendMessage={handleSendMessage}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

const StandardMessage = ({ message }) => {
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          p: 1,
          bgcolor: message.isSent ? '#c7d2fe' : 'white',
          color: message.isSent ? '#1e293b' : '#1e293b',
          border: '1px solid #c7d2fe',
          borderRadius: 3,
          paddingTop: 0.5, // Reduced top padding
          paddingBottom: 0.5, // Reduced bottom padding
          paddingLeft: 3, // Left padding
          paddingRight: 3, // Right padding
        }}>
        <div dangerouslySetInnerHTML={{ __html: message.text }} />
      </Paper>
      <Typography
        variant="caption"
        sx={{
          mt: 0.5,
          color: '#94a3b8',
        }}>
        {new Date(message.timestamp).toLocaleString('it-IT')}
      </Typography>
    </>
  );
};

const CustomerNote = ({ message }) => {
  return (
    <>
      <Typography
        variant="caption"
        sx={{
          mt: 0.5,
          color: 'text.primary',
          fontWeight: 'bold',
        }}>
        La tua nota
      </Typography>
      <Paper
        sx={{
          p: 1,
          bgcolor: '#f7f7f7',
          color: 'black',
          borderRadius: 1,
        }}>
        <div dangerouslySetInnerHTML={{ __html: message.text }} />
      </Paper>
      <Typography
        variant="caption"
        sx={{
          mt: 0.5,
          color: 'text.secondary',
        }}>
        {new Date(message.timestamp).toLocaleString('it-IT')}
      </Typography>
    </>
  );
};

const ChatEntry = ({ message, index, messagesLength, firstMessageRef }) => {
  // Function to format the timestamp
  const formatTimestamp = (timestamp) => {
    console.log('Raw timestamp:', timestamp); // Debugging line

    // Ensure the timestamp is valid
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      console.error('Invalid timestamp:', timestamp); // Log invalid timestamps
      return 'Data non valida'; // Fallback message
    }

    // Format the valid date
    return new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'short',
      timeStyle: 'medium',
    }).format(date);
  };
  switch (message.type) {
    case 1:
      return (
        <Box
          ref={index === messagesLength - 1 ? firstMessageRef : null}
          key={message.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: message.isSent ? 'flex-end' : 'flex-start',
            maxWidth: '80%',
            alignSelf: message.isSent ? 'flex-end' : 'flex-start',
          }}>
          <CustomerNote message={message} />;
        </Box>
      );
    case 7:
      return (
        <Box
          ref={index === messagesLength - 1 ? firstMessageRef : null}
          key={message.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}>
          <CustomerAcceptedEntry
            timestamp={formatTimestamp(message.timestamp)}
          />
        </Box>
      );
    case 8:
      return (
        <Box
          ref={index === messagesLength - 1 ? firstMessageRef : null}
          key={message.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}>
          <BusPartnerOfferSent timestamp={formatTimestamp(message.timestamp)} />
        </Box>
      );
    case 9:
      return (
        <Box
          ref={index === messagesLength - 1 ? firstMessageRef : null}
          key={message.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}>
          <BusPartnerConfirmedAvailability
            timestamp={formatTimestamp(message.timestamp)}
          />
        </Box>
      );
    case 10:
      return (
        <Box
          ref={index === messagesLength - 1 ? firstMessageRef : null}
          key={message.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}>
          <BusPartnerDeclined timestamp={formatTimestamp(message.timestamp)} />
        </Box>
      );
    default:
      return (
        <Box
          ref={index === messagesLength - 1 ? firstMessageRef : null}
          key={message.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: message.isSent ? 'flex-end' : 'flex-start',
            maxWidth: '80%',
            alignSelf: message.isSent ? 'flex-end' : 'flex-start',
          }}>
          <StandardMessage message={message} />
        </Box>
      );
  }
};

const BusPartnerOfferSent = ({ timestamp }) => {
  return (
    <>
      <Chip
        icon={
          <CheckCircleOutlinedIcon
            style={{ color: '#475569', fontSize: '20px' }}
          />
        }
        label="Preventivo inviato al cliente"
        sx={{
          marginTop: '20px',
          backgroundColor: 'white',
          color: '#475569',
          borderRadius: '24px',
          padding: '0 4px',
          fontWeight: '600',
          border: '1px solid #cbd5e1',
          fontSize: '14px',
        }}
      />
      <Typography
        variant="body2"
        sx={{
          color: '#94a3b8',
          marginTop: '8px', // Adds space between the chip and the sentence
          textAlign: 'center', // Aligns the text below the chip
          fontSize: '12px',
        }}>
        {timestamp}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: '#94a3b8',
          textAlign: 'center', // Aligns the text below the chip
          fontSize: '12px',
          marginBottom: '8px',
        }}>
        Controlla i dettagli e inserisci i tuoi dati per confermare la
        prenotazione.
      </Typography>
    </>
  );
};

const CustomerAcceptedEntry = ({ timestamp }) => {
  return (
    <>
      <Chip
        icon={
          <CheckCircleOutlinedIcon
            style={{ color: '#475569', fontSize: '20px' }}
          />
        }
        label="Hai accettato l'offerta"
        sx={{
          marginTop: '20px',
          backgroundColor: 'white',
          color: '#475569',
          borderRadius: '24px',
          padding: '0 4px',
          fontWeight: '600',
          border: '1px solid #cbd5e1',
          fontSize: '14px',
        }}
      />
      <Typography
        variant="body2"
        sx={{
          color: '#94a3b8',
          marginTop: '8px', // Adds space between the chip and the sentence
          textAlign: 'center', // Aligns the text below the chip
          fontSize: '12px',
        }}>
        {timestamp}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: '#94a3b8',
          textAlign: 'center', // Aligns the text below the chip
          fontSize: '12px',
          marginBottom: '8px',
        }}>
        {/* Conferma la disponibilità e invia i dettagli di pagamento */}
      </Typography>
    </>
  );
};

const BusPartnerConfirmedAvailability = ({ timestamp }) => {
  return (
    <>
      <Chip
        icon={
          <CheckCircleOutlinedIcon
            style={{ color: '#475569', fontSize: '20px' }}
          />
        }
        label="Disponibilità confermata dalla compagnia"
        sx={{
          marginTop: '20px',
          backgroundColor: 'white',
          color: '#475569',
          borderRadius: '24px',
          padding: '0 4px',
          fontWeight: '600',
          border: '1px solid #cbd5e1',
          fontSize: '14px',
        }}
      />
      <Typography
        variant="body2"
        sx={{
          color: '#94a3b8',
          marginTop: '8px', // Adds space between the chip and the sentence
          textAlign: 'center', // Aligns the text below the chip
          fontSize: '12px',
        }}>
        {timestamp}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: '#94a3b8',
          textAlign: 'center', // Aligns the text below the chip
          fontSize: '12px',
          marginBottom: '8px',
        }}></Typography>
    </>
  );
};

const BusPartnerDeclined = ({ timestamp }) => {
  return (
    <>
      <Chip
        icon={
          <HighlightOffOutlinedIcon
            style={{ color: '#475569', fontSize: '20px' }}
          />
        }
        label="Richiesta di viaggio declinata"
        sx={{
          marginTop: '20px',
          backgroundColor: '#fecaca',
          color: '#475569',
          borderRadius: '24px',
          padding: '0 4px',
          fontWeight: '600',
          border: '1px solid #475569',
          fontSize: '14px',
        }}
      />
      <Typography
        variant="body2"
        sx={{
          color: '#94a3b8',
          margin: '8px 0', // Adds space between the chip and the sentence
          textAlign: 'center', // Aligns the text below the chip
          fontSize: '12px',
        }}>
        {timestamp}
      </Typography>
    </>
  );
};

const SendButton = ({ handleSendMessage, messageToSend, isLoading }) => {
  return (
    <IconButton
      color="primary"
      onClick={handleSendMessage}
      disabled={
        isLoading || messageToSend == null || messageToSend.trim() === ''
      }
      sx={{
        m: 1,
        bgcolor: '#4f46e5',
        color: 'white',
        '&:hover': {
          bgcolor: '#4338ca',
        },
        '&.Mui-disabled': {
          bgcolor: 'action.disabledBackground',
          color: 'action.disabled',
        },
        width: 36, // Set a fixed width for the button
        height: 36, // Set a fixed height for the button
        borderRadius: '10px', // Adjusting the roundness
        alignSelf: 'flex-start',
      }}>
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <SendIcon sx={{ fontSize: 'large', marginRight: '-2px' }} />
      )}
    </IconButton>
  );
};

export default ClientChat;
